












import Vue from "vue";
import Post from "../../components/Page/Post.vue";
export default Vue.extend({
	name: "Timeline",
	components: {
		Post
	},
	data() {
		return {
			posts: [],
			isPostLoadClear: false
		};
	},
	created() {
		this.reload();
	},
	methods: {
		reload() {
			this.$store.commit("pushPageLoading", {
				name: "GET_CLUB_POSTS",
				message: "동아리 글 불러오는 중"
			});
			this.$store
				.dispatch("GET_CLUB_POSTS")
				.then(posts => {
					this.$store.commit("clearPageLoading", "GET_CLUB_POSTS");
					this.isPostLoadClear = true;
					this.posts = posts;
				})
				.catch(err => {});
		}
	},
	computed: {
		getClub(): any {
			return this.$store.state.club;
		},
		getPermissionCreate(): boolean {
			if (this.getClub.ranks) {
				if (this.getClub.owner == this.$store.state.userInformation._id) return true;
				let user = this.getClub.members.find((member: any) => {
					return member.user == this.$store.state.userInformation._id;
				});
				if (user) {
					let permission = this.getClub.ranks.find((rank: any) => rank.id == user.rank).permission.map((x: any) => parseInt(x));
					return permission.indexOf(1) != -1;
				} else return false;
			} else return false;
		}
	}
});
