














































































































import Vue from "vue";
import TopLoadingBar from "../TopLoadingBar.vue";
export default Vue.extend({
	components: {
		TopLoadingBar
	},
	props: {
		data: Object,
		isCreate: Boolean
	},
	data() {
		return {
			isShowOption: false,
			isModifPost: false,
			modifContent: "",
			comment: "",
			comments: [],
			isShowComments: false,
			images: [],
			base64images: [],
			isLoading: false
		};
	},
	created() {
		if (this.isCreate) {
			this.isModifPost = true;
		}
	},
	methods: {
		toggleOption() {
			this.isShowOption = !this.isShowOption;
		},
		toggleComments() {
			this.isShowComments = !this.isShowComments;
			if (this.isShowComments && this.comments) {
				this.commentReload();
			}
		},
		commentReload() {
			this.isLoading = true;
			this.$store
				.dispatch("POST_COMMENT", {
					_id: this.data._id
				})
				.then(data => {
					this.isLoading = false;
					this.comments = data.reverse();
				})
				.catch(err => console.log(err));
		},
		deletePost() {
			if (!this.isLoading) {
				this.isLoading = true;
				this.$store
					.dispatch("POST_DELETE", {
						_id: this.data._id
					})
					.then(data => {
						this.isLoading = false;
						this.$emit("isChange", false);
					})
					.catch(err => console.log(err));
			}
		},
		modifPost() {
			this.modifContent = this.data.content;
			this.isShowOption = false;
			this.isModifPost = !this.isModifPost;
		},
		changeContentSave() {
			if (this.modifContent.trim().length > 0) {
				this.isLoading = true;
				if (this.isCreate) {
					if (this.images) {
						this.encodeBase64ImageFiles(this.images)
							.then(base64images => {
								this.$store
									.dispatch("POST_WRITE", {
										content: this.modifContent,
										img: base64images
									})
									.then(data => {
										this.images = [];
										this.isLoading = false;
										this.modifContent = "";
										this.$emit("isChange", false);
									})
									.catch(err => console.log(err));
							})
							.catch(err => console.log(err));
					} else {
						this.$store
							.dispatch("POST_WRITE", {
								content: this.modifContent
							})
							.then(data => {
								this.isLoading = false;
								this.modifContent = "";
								this.$emit("isChange", false);
							})
							.catch(err => console.log(err));
					}
				} else {
					this.$store
						.dispatch("POST_MODIFICATION", {
							_id: this.data._id,
							content: this.modifContent
						})
						.then(data => {
							this.isLoading = false;
							this.isShowOption = false;
							this.isModifPost = false;
							this.$emit("isChange", false);
						})
						.catch(err => console.log(err));
				}
			}
		},
		pressEnter(e: any) {
			if (e.keyCode == 10 && e.ctrlKey) {
				this.changeContentSave();
			}
		},
		pressEnterComment(e: any) {
			if (e.keyCode == 13) {
				this.createComment();
			}
		},
		createComment() {
			if (!this.isLoading && this.comment.trim().length > 0) {
				this.isLoading = true;
				this.$store
					.dispatch("POST_COMMENT_WRITE", {
						_id: this.data._id,
						message: this.comment
					})
					.then(() => {
						this.isLoading = false;
						this.comment = "";
						this.commentReload();
					})
					.catch(err => {});
			}
		},
		removeComment(id: string) {
			if (!this.isLoading) {
				this.isLoading = true;
				this.$store
					.dispatch("POST_COMMENT_DELETE", {
						_id: id,
						_postid: this.data._id
					})
					.then(() => {
						this.isLoading = false;
						this.commentReload();
					})
					.catch(err => {});
			}
		},
		toggleLike() {
			if (!this.isLoading) {
				this.isLoading = true;
				this.$store
					.dispatch("POST_TOGGLE_LIKE", {
						_id: this.data._id
					})
					.then(() => {
						this.isLoading = false;

						this.$emit("isChange", false);
					})
					.catch(err => {});
			}
		},
		onImageChange(e: any) {
			this.images = e.target.files;
			this.encodeBase64ImageFiles(this.images);
		},
		encodeBase64ImageFile(image: File): Promise<string> {
			return new Promise<string>((resolve, reject) => {
				let reader = new FileReader();
				reader.readAsDataURL(image);
				reader.onload = (event: any) => {
					resolve(event.target.result);
				};
				reader.onerror = error => {
					reject(error);
				};
			});
		},
		encodeBase64ImageFiles(images: File[]) {
			let buffer: Promise<string>[] = [];
			[...images].forEach(image => {
				buffer.push(this.encodeBase64ImageFile(image));
			});
			return Promise.all(buffer);
		},
		checkOwner(owner: any): boolean {
			return this.getUserInformation._id == owner._id;
		}
	},
	computed: {
		getImages(): File[] {
			return [...this.images];
		},
		getMainPath(): string {
			return this.$store.state.mainPath;
		},
		getUserInformation(): any {
			return this.$store.state.userInformation;
		},
		getClub(): any {
			return this.$store.state.club;
		},
		isLike(): boolean {
			if (this.getUserInformation)
				return (
					this.data.likes.indexOf(this.getUserInformation._id) != -1
				);
			else return false;
		},
		getPermissionModifAndDelete(): boolean {
			if (this.getUserInformation._id == this.data.owner._id) return true;
			else {
				if (this.getClub.ranks) {
					if (
						this.getClub.owner ==
						this.$store.state.userInformation._id
					)
						return true;
					let user = this.getClub.members.find((member: any) => {
						return (
							member.user == this.$store.state.userInformation._id
						);
					});
					if (user) {
						let permission = this.getClub.ranks.find(
							(rank: any) => rank.id == user.rank
						).permission;
						return permission.indexOf(3) != -1;
					} else return false;
				} else return false;
			}
		}
	}
});
